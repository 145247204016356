import axios from "@/axios";

export default {
  async getAllReportingCategory({ commit }, data){
    try {
      const response = await axios.get("/api/v1/admins/reporting-category/all/list", { params: data });
      return response.data.data
    } catch (error) {
      return error
    }
  },
  async addCategory({commit}, data) {
    try {
      const response = await axios.post('/api/v1/admins/reporting-category/create', data);
      return response
    } catch (error) {
      return error
    }
  },
  async getParentReportingCategoryList({commit}) {
    try {
      const response = await axios.get('/api/v1/admins/reporting-category/list');
      return response
    } catch (error) {
      return error
    }
  },
  async getCategoryDetails({commit}, data) {
    try {
      const response = await axios.get('/api/v1/admins/reporting-category/details/' + data);
      return response
    } catch (error) {
      return error
    }
  },
  async updateCategoryDetails({commit},data){
    try {
      const response = await axios.post('/api/v1/admins/reporting-category/update/' + data.id, data.info);
      return response
    } catch (error) {
      return error
    }
  },
  async deleteReportingCategoryById({commit},id){
    try {
      const response = await axios.delete('/api/v1/admins/reporting-category/delete/' + id);
      return response
    } catch (error) {
      return error
    }
  },
};

import axios from '@/axios';

export default {
    fetchAccountLevels({ commit }) {
        return axios.get('/api/v1/admins/account-levels');
    },
    createAccountLevel({ commit }, data) {
        return axios.post('/api/v1/admins/account-levels/create', data);
    },
    updateAccountLevel({ commit }, data) {
        return axios.patch(`/api/v1/admins/account-levels/update/${data._id}`, data);
    },
    deleteAccountLevel({ commit }, { _id }) {
        return axios.delete(`/api/v1/admins/account-levels/delete/${_id}`);
    }
}

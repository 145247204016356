import state from "./moduleReportingCategoryState.js";
import mutations from "./moduleReportingCategoryMutations.js";
import actions from "./moduleReportingCategoryActions.js";
import getters from "./moduleReportingCategoryGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default [
  {
    path: "reporting/categories/add",
    name: "AddReportingCategory",
    component: () =>
      import("@/views/superAdmin/store/category/addReportingCategory.vue"),
    meta: {
      breadcrumb: [
        { title: "Home", url: "/super-admin/dashboard" },
        { title: "Category list", url: "/super-admin/store/categories?activeTab=1" },
        { title: "Add", active: true },
      ],
      pageTitle: "Add Reporting Category",
      rule: "editor",
    },
  },
  {
    path: "reporting/categories/edit/:reportingCategoryId",
    name: "EditReportingCategory",
    component: () =>
      import("@/views/superAdmin/store/category/editReportingCategory.vue"),
    meta: {
      breadcrumb: [
        { title: "Home", url: "/super-admin/dashboard" },
        { title: "Category list", url: "/super-admin/store/categories?activeTab=1" },
        { title: "Edit", active: true },
      ],
      pageTitle: "Edit Category",
      rule: "editor",
    },
  },
]

/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  fetchProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
        jwt
        .fetchHttpProduct(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchOversightProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
        jwt
        .fetchHttpOversightProduct(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchClinicOversightProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
        jwt
        .fetchHttpClinicOversightProduct(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchBrands({ commit }) {
    return new Promise((resolve, reject) => {
        jwt
        .fetchBrands()
        .then(res => {
          return resolve(res.data.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchProductApplicableAreas({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpApplicableAreas(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchAllProductApplicableAreas({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAllHttpApplicableAreas(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  saveProductData({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveHttpProduct(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  saveNewApplicableArea({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveHttpApplicableArea(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateProductDetails({commit},data){
        return new Promise((resolve,reject)=>{
          jwt
            .updateHttpProductDetail(data)
            .then((res) => {
            resolve(res);
          }).catch(err =>{
            reject(err)
          })
    });

      },
  updateApplicableAreaDetail({commit},data){
    return new Promise((resolve,reject)=>{
      jwt
        .updateHttpApplicableArea(data)
        .then((res) => {
          resolve(res);
        }).catch(err =>{
        reject(err)
      })
    });

  },
  deleteProductServer({commit},id){
    return new Promise((resolve, reject) => {
      jwt
        .deleteHttpProduct(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  deleteApplicableAreaServer({commit},id){
    return new Promise((resolve, reject) => {
      jwt
        .deleteHttpApplicableArea(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchProductDetail({commit},id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpProductDetail(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchApplicableAreaDetail({commit},id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpApplicableAreaDetail(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchAllProductslist() {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAllProducts()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  createCustomProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v2/products/create-custom-product", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProductDetailsV2({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/products/${data.id}`, { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProductsV2({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/products/list/${data.organizationId}`, 
          { params: data }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveNewCustomApplicableArea({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v2/products/create-custom-applicable-area", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAllProductApplicableAreasV2({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v2/products/all-applicable-areas", { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCustomProduct({commit},data){
    return new Promise((resolve,reject)=>{
      axios
        .patch(`/api/v2/products/${data.id}/update`, data.body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCustomApplicableArea({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v2/products/${data.id}/update-custom-applicable-area`, data.payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCustomApplicableArea({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v2/products/${data.id}/delete-custom-applicable-area`, { data: data.payload })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};

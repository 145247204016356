import "firebase/auth";
import axios from "@/axios";
import jwt from "../../http/requests/auth/jwt";
import axios2 from "axios";

export default {
  fetchUserDetails({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchUserDetail()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchCardDetails({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchCardDetail()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchCardDetailsList({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchCardListApi()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchAccountPaymentDetailsList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAccountPaymentDetailsListApi(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchClinicPaymentDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchClinicPaymentDetailsApi(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  removeUserStripeCard({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .removeCardDetailApi()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  saveCardDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .saveCardDetailApi(payload)
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );

          commit("UPDATE_USER_INFO", res.data.data, { root: true });
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchClinicCardDetailsForAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchClinicCardDetailForAdmin(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/v1/updateWebProfile", data)
        .then(res => {
          if (
            res.data.data.userType !== "doctor" &&
            res.data.data.userType !== "nurse"
          ) {
            localStorage.setItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
              res.data.data.firstName
            );

            localStorage.setItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
              JSON.stringify(res.data.data)
            );
            commit("UPDATE_USER_INFO", res.data.data, { root: true });
          } else {
            commit("UPDATE_USER_TEMP_PROFILE_INFO", res.data.data, {
              root: true
            });
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateNurseProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/v1/updateNurseWebProfile", data)
        .then(res => {
          commit("UPDATE_USER_TEMP_PROFILE_INFO", res.data.data, {
            root: true
          });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAdminProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/v1/updateAdminWebProfile", data)
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );
          commit("UPDATE_USER_INFO", res.data.data, { root: true });

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateFranchiseOrClinicProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/v1/updateFranchiseOrClinicWebProfile", data)
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );

          commit("UPDATE_USER_INFO", res.data.data, { root: true });

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateClinicWebProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/v1/updateClinicWebProfile", data)
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );

          commit("UPDATE_USER_INFO", res.data.data, { root: true });

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteUploadedFile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/deleteUploadedFile", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/getUserById/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  verifyUserEmailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/verifyUserEmail/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserDetailForProfile({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/getUserById/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  approveRejectUpdatedProfile({ commit }, data) {
    if (!data.rejectReason) data.rejectReason = "NA";
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/updatedProfileReview/" +
            data.uid +
            "/" +
            data.action +
            "/" +
            data.rejectReason
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchNotificationBadge({ commit }, data) {
    jwt
      .getNotificationBadgeCount()
      .then(response => {
        commit("UPDATE_USER_BADGE_COUNT", response.data.data, { root: true });
      })
      .catch(error => {
        //
      });
  },
  fetchNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/notifications/getCmsNotifications`, { params: data })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPaginatedNotifications({ commit }, data) {
    return axios.get(`/api/v1/notifications/cms/paginate`, { params: data })
  },
  readNotifications({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/notifications/${id}/read`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearBadgeCount({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/notifications/clearCmsNotificationBadge`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  unassociateClinic({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/unassociateClinic/${param.clinicId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAssociatedClinics({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/fetchAssociatedClinics?page=" +
            param.options.page +
            "&limit=" +
            param.options.limit +
            "&search=" +
            param.options.search +
            "&sort=" +
            param.options.sort +
            "&dir=" +
            param.options.dir +
            "&uid=" +
            param.uid
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAdminSentNotifications({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/fetchAdminSentNotifications?page=" +
            param.page +
            "&limit=" +
            param.limit +
            "&search=" +
            param.search +
            "&sort=" +
            param.sort +
            "&dir=" +
            param.dir
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteAdminSentNotifications({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/admins/adminSentNotifications/delete?id=" + param)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAdminAggregatedReport({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/fetchAdminAggregatedReport?page=" +
            param.page +
            "&limit=" +
            param.limit +
            "&search=" +
            param.search +
            "&sort=" +
            param.sort +
            "&dir=" +
            param.dir +
            "&activeClinicId=" +
            param.activeClinicId +
            "&month=" +
            param.month +
            "&year=" +
            param.year +
            "&status=" +
            param.status
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAdminAggregatedReportDetail({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/fetchAdminAggregatedReportDetail?id=" + param)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  proceedDoctorPayout({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/proceedDoctorPayout?id=" + param)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  payInvoiceAmountToDoctor({ commit }, invoiceId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/payAdminAggregatedReport?id=" + invoiceId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAdminClinicInvoice({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/fetchAdminClinicInvoice?page=" +
            param.page +
            "&limit=" +
            param.limit +
            "&search=" +
            param.search +
            "&sort=" +
            param.sort +
            "&dir=" +
            param.dir +
            "&activeClinicId=" +
            param.activeClinicId +
            "&clinicId=" +
            param.clinicId +
            "&year=" +
            param.year +
            "&month=" +
            param.month +
            "&status=" +
            param.status
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  payAdminClinicInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/fetchAdminClinicInvoice/pay", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendNotificationByAdmin({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/adminSentNotifications/send", param)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  connectedToXeroSuccessfully({ commit }) {
    localStorage.setItem("xeroConnectionStatus", true);
    commit(
      "UPDATE_XERO_CONNECTION_STATUS",
      { xeroConnectionStatus: true },
      { root: true }
    );
  },
  deleteInvoiceDataFromDb({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clearClinicInvoiceTable")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteDoctorInvoiceDataFromDb({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clearDoctorInvoiceTable")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  showScriptPdf({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/script/pdf?appointmentId=" + param.appointmentId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //upcoming invoices
  fetchAdminClinicUpcomingInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .httpFetchAdminClinicUpcomingInvoice(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchAdminClinicInvoiceDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .httpFetchAdminClinicInvoiceDetails(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  saveUpcomingInvoiceTreatmentDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .httpSaveUpcomingInvoiceTreatmentDetail(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  saveUpdatedTreatmentDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .httpSaveUpdatedTreatmentDetail(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  sendUpdatedTreatmentPdf({ commit }, invoiceId) {
    return new Promise((resolve, reject) => {
      jwt
        .httpSendUpdatedTreatmentPdf(invoiceId)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  voidEntirePaymentForClinic({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .httpVoidEntirePaymentForClinic(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  removeUserBankDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .removeUserBankDetails(payload)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  removeCreditCardDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .removeCreditCardDetails(payload)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  setDefaultPaymentMethod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .setDefaultPaymentMethod(payload)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  exportClinicInvoiceCsv({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .httpExportClinicInvoiceCsv(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  exportDoctorInvoiceCsv({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .httpExportDoctorInvoiceCsv(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  taxJarCalculateOrderTax({ commit }, data) {
    return new Promise((resolve, reject) => {
      console.log("in data", data);
      axios
        .post("/api/v1/taxjar/calculateTax", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

import axios from "@/axios";
import jwt from "../../http/requests/auth/jwt";

export default {
  fetchProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/store/products/list", { params: data })
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchStoreLimitation({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/store/products/limitation")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchStoreBulkDiscount({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/store/products/discount")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchStorePromo({ commit }) {
    return axios.get("/api/v1/store/products/promo")
  },
  fetchClinicDiscounts({ commit }, clinicId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/clinics/discounts?clinicId=${clinicId}`)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchStoreFreeShipping({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/store/products/freeShipping")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/store/products/fetchCategories")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchPrimaryCategories({ commit }) {
    return axios.get("/api/v1/store/products/fetchPrimaryCategories")
  },
  fetchSecondaryCategories({ commit }) {
    return axios.get("/api/v1/store/products/fetchSecondaryCategories")
  },
  fetchSuppliers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/store/products/fetchSuppliers")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  deleteProductVariant({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v1/store/products/${param.productId}/deleteVariant?variantId=${param.variantId}`
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  storeProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/store/products/save", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  findProductById({ commit }, pid) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/store/products?id=" + pid)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  removeProduct({ commit }, pid) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/store/products/${pid}/delete`)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateProduct({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/v1/store/products/${param.id}/update`, param.data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateProductVariant({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/api/v1/store/products/${param.id}/updateProductVariant`,
          param.data
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  addMoreProductImage({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/v1/store/products/${param.id}/addMoreProductImage`,
          { images: param.images, type: param.type }
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateProductImageStatus({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/v1/store/products/${param.id}/updateProductImageStatus`,
          param
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  duplicateProduct({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/v1/store/products/${param.id}/duplicate`,
          param
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateProductHideStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateProductHttpHideStatus(data)
        .then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
    });
  },
  updateProductsOrderNumber({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.patch("/api/v1/store/products/updateProductsOrder", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
  },
  deleteProduct({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v1/store/products/${id}/delete`,
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  addProductsToCategory({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/store/products/addProductsToCategory`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchProductsNotInCategory({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/store/products/notInCurrentCategory?search=" +
          data.search +
          "&categoryId=" +
          data.categoryId
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchProductsNotInBrand({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/store/products/notInCurrentBrand", { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addProductsToBrand({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v1/store/products/addProductsToBrand', data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchProductTypes({commit}){
    return new Promise((resolve, reject) => {
      axios
        .get('/api/v1/store/products/getProductTypes')
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  addBundledItems({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v1/store/products/addBundleItems', data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  fetchBundledItems({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/store/products/getBundledItems/${data.parentProductId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  updateBundleItems({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/v1/store/products/updateBundledItems/${data.parentProductId}`, data.items)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  deleteBundleItems({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/store/products/deleteBundleItems/${data.parentProductId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  fetchEvolusProducts({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/store/products/filterEvolusProducts", {
            params: {
              id: data.id
            }
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios";

export default {
  getAppointmentDetailsV2({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/appointments/${data}?limitNotes=10`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveAppointmentNote({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v2/appointments/${data.appointmentId}/notes`, {
          proceduralNote: data.proceduralNote,
          additionalNote: data.additionalNote,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  saveAppointmentPhoto({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v2/appointments/${data.appointmentId}/upload-photos`, data.photos)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  uploadMultipleFiles({ commit }, {event, isBeforePhoto, appointmentId}) {
    return new Promise((resolve, reject) => {
      let data = new FormData();

      // Append all files from the file input
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        data.append('appointmentImage', files[i]);
      }

      let config = {
        header: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          isBeforePhoto: isBeforePhoto,
          appointmentId: appointmentId,
        },
      };

      axios
        .post(`/api/v2/appointments/upload-multiple-files`, data, config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  deleteAppointmentFile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v2/appointments/delete-appointment-file`, {data})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  uploadNoteFiles({ commit }, {event}) {
    return new Promise((resolve, reject) => {
      let data = new FormData();
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      // Append all files from the file input
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        data.append('appointmentImage', files[i]);
      }

      axios
        .post(`/api/v2/appointments/upload-note-files`, data, config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
};

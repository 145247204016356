import store from "../store/store";
import axios from "@/axios";

export function handleProductDetailRedirect(to, from, next) {
  const urlParts = from.path.split("/");
  // Validate if the path has the minimum length to be a product detail page
  if (urlParts.length < 4) {
    return next();
  }

  const isSuperAdmin = urlParts[1] === "super-admin";
  const isShopPromotionsPath =
    urlParts.length === 4 && urlParts[3] === "promotions";
  const relevantPart = isSuperAdmin ? urlParts[4] : urlParts[3];
  const isBrandOrCategoryPath =
    relevantPart === "brands" || relevantPart === "category";
  const updateQueryAndProceed = (fromPage) => {
    const updatedQuery = { ...to.query, from: fromPage, fromPath: from.path };
    next({ path: to.path, query: updatedQuery });
  };
  function formatString(input) {
    if (!input) return "";
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  if (isShopPromotionsPath) {
    return to.query.from && to.query.fromPath
      ? next()
      : updateQueryAndProceed(formatString(urlParts[3]));
  }

  if (isBrandOrCategoryPath) {
    return to.query.from && to.query.fromPath
      ? next()
      : updateQueryAndProceed(formatString(isSuperAdmin ? urlParts[5] : urlParts[4]));
  }

  next();
}

/**
 * Handles the redirection logic before entering a route that requires a specific permission.
 *
 * @param {Object} to - The target route object.
 * @param {Object} from - The current route object.
 * @param {Function} next - The next function to call to continue the navigation.
 * @param {string} permissionName - The name of the permission required to access the route.
 * @param {string} [homePath] - The path to redirect to if the user doesn't have the required permission.
 * @returns {void}
 */
export async function handleBeforeEnterPermissionRedirect (to, from, next, permissionName, homePath) {
  try {
    const userId = store.state.AppActiveUser.id;
    const organizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
    const authHeader = {
      Authorization: token
    }

    const { data: permissionData } = await axios.get(`/api/v1/admins/user-permission?userId=${userId}&organizationId=${organizationId}`, {
      headers: authHeader
    })

    const hasPermission = permissionData.filter((item) => item.permission.name === permissionName);

    if (hasPermission.length) {
      next();
    } else {
      next({ path: `/${homePath || "home"}` });
    }
  } catch (error) {
    console.log(`Error fetching: ${error}`)
    next({ path: from.path });
  }
}

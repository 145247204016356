
/**
 * Formats a price value by dividing by 100 and ensuring it has 2 decimal points.
 * If the input is not a number, it returns the input as-is.
 * @param {string} dataType - The data type to return (either 'string' or 'number').
 * @returns {string|*} - The formatted price as a string with 2 decimal places, the unformmatted number
 * if the dataType = 'number' or the original value if invalid.
 */
function formatPriceValue(value, dataType = 'string') {
  if (typeof value !== 'number' || isNaN(value)) {
    return value; // Return the input if it's not a valid number.
  }

  // Divide by 100 to get the decimal price
  const price = value / 100;

  if (dataType === 'number') {
    return price;
  }

  // Format the price with 2 decimal places
  return price.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}


export {
  formatPriceValue,
}
import jwt from "../../http/requests/auth/jwt/index.js";
import axios from "@/axios";

export default {
  addDiscount({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveHttpDiscount(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getDiscountDetails({commit}, id){
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpDiscount(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getDiscountList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpDiscountList(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchDiscountList({commit},) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAllHttpDiscount()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateDiscountDetails({commit},data){
    return new Promise((resolve,reject)=>{
      jwt
        .updateHttpDiscountDetail(data)
        .then((res) => {
          resolve(res);
        }).catch(err =>{
        reject(err)
      })
    });
  },
  deleteDiscountById({commit},id){
    return new Promise((resolve, reject) => {
      jwt
        .deleteHttpDiscount(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  bulkDiscountList({ commit }, data) {
    return axios.get('/api/v1/admins/discounts', { params: data })
  },
  createBulkDiscount({ commit }, data) {
    return axios.post('/api/v1/admins/discounts', data)
  },
  deleteBulkDiscount({ commit }, id) {
    return axios.delete(`/api/v1/admins/discounts/${id}`)
  },
  updateBulkDiscount({ commit }, data) {
    return axios.put(`/api/v1/admins/discounts/${data.id}`, data.body)
  },
  getBulkDiscountDetails({ commit }, id) {
    return axios.get(`/api/v1/admins/discounts/${id}`)
  },
  getBulkDiscountGroups({ commit }, data) {
    return axios.get('/api/v1/admins/discounts/groups', { params: data })
  },
  createBulkDiscountGroup({ commit }, data) {
    return axios.post('/api/v1/admins/discounts/groups', data)
  },
  updateBulkDiscountGroup({ commit }, data) {
    return axios.put(`/api/v1/admins/discounts/groups/${data.id}`, data.body)
  },
  deleteBulkDiscountGroup({ commit }, id) {
    return axios.delete(`/api/v1/admins/discounts/groups/${id}`)
  },
};

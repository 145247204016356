/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios";

export default {
  getMonthlyCompliance({ commit }, data) {
    let path =
      "/api/v1/monthlyCompliance/" + data.organizationId + "/complianceList";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            sort: data.sort,
            dir: data.dir,
            search: data.search,
            year: data.year,
            month: data.month,
            clinic: data.clinic,
            status: data.status,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSuperAdminComplianceCount({ commit }, data) {
    let path =
      "/api/v1/monthlyCompliance/superAdminComplianceCount";
    return new Promise((resolve, reject) => {
      axios
        .get(path, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSuperAdminCompliance({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/superAdminCompliance";
    return new Promise((resolve, reject) => {
      axios
        .get(path, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPreviousMonthCompliance({ commit }, data) {
    let path =
      "/api/v1/monthlyCompliance/previousMonthCompliance/" + data.clinicId;
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            month: data.month,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadFiles({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/uploadFiles";
    return new Promise((resolve, reject) => {
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(path, data, config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  downloadPdf({ commit }, url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { responseType: 'blob' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  startCompliance({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/start/" + data;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  udpatebookingDetails({ commit }, data) {
    let path =
      "/api/v1/monthlyCompliance/updateBookingDetails/" + data.clinicId;
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addSuperAdminGeneralNotes({ commit }, data) {
    let path =
      "/api/v1/monthlyCompliance/superAdminGeneralNotes/" + data.clinicId;
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addSuperAdminChangeRequest({ commit }, data) {
    let path =
      "/api/v1/monthlyCompliance/superAdminChangeRequest/" + data.complianceId;
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updatePhotos({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/updatePhotos/" + data.clinicId;
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  approveRejectCompliance({ commit }, data) {
    let path =
      "/api/v1/monthlyCompliance/approveRejectCompliance/" + data.clinicId;
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateVideos({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/updateVideos/" + data.clinicId;
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPreviousMonthDetails({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/previousMonthComplianceNotes/" + data.clinicId;
    return new Promise((resolve, reject) => {
      axios
        .post(path, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDetails({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/" + data.clinicId + "/details"; //data.clinicId is the complianceID
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchOrganisationComplianceList({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/" + data.orgId + "/complianceList";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            sort: data.sort,
            search: data.search,
            year: data.year,
            month: data.month,
            clinic: data.clinic,
            status: data.status,
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUnsetProducts({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/" + data.clinicId + "/clinic/unset-products?month=" + data.month + "&year=" + data.year;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchClinicDrugbookProducts({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/" + data.clinicId + "/clinic/stocktake?complianceId=" + data.complianceId;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  insertStockTakeProduct({ commit }, data) {
    let path = "api/v1/monthlyCompliance/" + data.complianceId + "/stocktake"
    return new Promise((resolve, reject) => {
      axios
        .post(path, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  updateStockTakeProduct({ commit }, data) {
    let path = "api/v1/monthlyCompliance/" + data.complianceId + "/stocktake/" + data.productId
    return new Promise((resolve, reject) => {
      axios
        .put(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  fetchStocktakeFlagFeature({ commit }, data) {
    // let path = "/api/v1/monthlyCompliance/" + data.clinicId + "/clinic/stocktake?complianceId=" + data.complianceId;
    let path = `api/v1/monthlyCompliance/${data.orgId}/${data.clinicId}/stocktake/status`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
